<template>
  <v-container fluid>
    <PageToolbar
      :title="$tc('global.orcamento', 2)"
      icon="mdi-file-percent-outline"
      dark
    />
    <DialogOrcamento
      v-if="dialogOrcamento"
      :dialogOrcamento.sync="dialogOrcamento"
      :editar="editar"
      :item="orcamento"
      @fetch-orcamentos="fetchOrcamentos"
    />
    <v-card>
      <v-card-title class="tocs_gray_1">
        <v-text-field
          style="max-width: 400px"
          v-model="search"
          append-icon="mdi-magnify"
          :label="$tc('global.buscar')"
          clearable
          outlined
          solo
          flat
          dense
          hide-details
        >
        </v-text-field>
        <v-spacer class="d-none d-sm-flex"></v-spacer>

        <v-btn
          v-if="POrcamentoAdicionar"
          @click="addOrcamento"
          color="button_1"
          class="white--text mt-4 mt-sm-0"
        >
          <v-icon left>mdi-plus</v-icon>
          {{ $tc("global.add") }}
        </v-btn>
      </v-card-title>
      <v-divider></v-divider>

      <v-card-text>
        <v-data-table
          :loading="loading"
          :headers="headers"
          :options.sync="options"
          :server-items-length="totalOrcamentos"
          :items="orcamentos"
          :items-per-page="10"
          class="data-tables"
          :class="{ 'data-tables__row-click': POrcamentoVisualizar }"
          @click:row="goToOrcamento"
        >
          <template v-slot:item.data="{ item }">
            {{ item.data | dateFormat("dd/MM/yyyy") }}
          </template>
          <template v-slot:item.valor="{ item }">
            {{ item.valor | currency }}
          </template>
          <template v-slot:item.desconto="{ item }">
            {{ item.desconto | currency }}
          </template>
          <template v-slot:item.total="{ item }">
            {{ item.total | currency }}
          </template>
          <template v-slot:item.status="{ item }">
            <span v-if="item.status === 0"> Aberto </span>
            <span v-if="item.status === 1"> Finalizado </span>
            <span v-if="item.status === -1"> Cancelado </span>
          </template>

          <template v-slot:item.actions="{ item }">
            <v-btn
              :disabled="!POrcamentoVisualizar"
              icon
              @click.stop="gerarpdf(item)"
            >
              <v-icon>mdi-file-export</v-icon>
            </v-btn>
          </template>

          <template v-slot:item.actionsAvancado="{ item }">
            <v-btn
              :disabled="!POrcamentoVisualizar"
              icon
              @click.stop="gerarPdfAvancado(item)"
            >
              <v-icon>mdi-file-export</v-icon>
            </v-btn>
          </template>
        </v-data-table>
        <PdfDialogComp
          v-if="pdfDialogSimples"
          :pdfDialog.sync="pdfDialogSimples"
          :pdfData="pdfDataSimples"
          :title="`Orçamento Nº.: ${this.orcamento.id}`"
        />
        <PdfDialog
          v-if="pdfDialogAvancado"
          :pdfDialog.sync="pdfDialogAvancado"
          :pdfData="pdfDataAvancado"
          :empresa="empresa"
          :title="`Orçamento Nº.: ${this.orcamento.id}`"
        />
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import {
  getOrcamentos,
  orcamentoPdfSimples,
  orcamentoPdfAvancado,
} from "@/api/orcamentos/orcamentos.js";
import { mapState, mapGetters } from "vuex";

const pdfGenerator = () =>
  import(/* webpackChunkName: "venda-pdf" */ "./reports/orcamento.js");

export default {
  name: "Orcamentos",

  data() {
    return {
      search: "",
      loading: false,
      orcamentos: [],
      orcamento: {},
      editar: false,
      dialogOrcamento: false,
      loadingPdfSimples: false,
      pdfDialogSimples: false,
      pdfDataSimples: "",
      pdfDialogAvancado: false,
      pdfDataAvancado: "",
      totalOrcamentos: 0,
      options: {
        sortBy: ["id"],
        sortDesc: [false],
        page: 1,
        itemsPerPage: 15,
      },
    };
  },

  components: {
    DialogOrcamento: () => import("./components/DialogOrcamento.vue"),
    PdfDialog: () => import("/src/components/global/PdfDialog.vue"),
  },

  computed: {
    ...mapGetters("Componentes", ["getAccess"]),
    POrcamentoVisualizar() {
      return this.getAccess("Orcamentos", "visualizar");
    },
    POrcamentoAdicionar() {
      return this.getAccess("Orcamentos", "adicionar");
    },
    headers() {
      return [
        {
          text: this.$tc("global.cliente"),
          value: "cliente_nome",
        },
        {
          text: this.$tc("global.data"),
          value: "data",
        },
        {
          text: this.$tc("global.moeda"),
          value: "moeda_sigla",
        },
        {
          text: this.$tc("global.valor"),
          value: "valor",
        },
        {
          text: this.$tc("global.desconto"),
          value: "desconto",
        },
        {
          text: this.$tc("global.total"),
          value: "total",
        },
        {
          text: this.$tc("global.status"),
          value: "status",
        },
        {
          text: "O. Simples",
          width: "150px",
          align: "center",
          value: "actions",
        },
        {
          text: "O. Avançado",
          width: "150px",
          align: "center",
          value: "actionsAvancado",
        },
      ];
    },
    ...mapState("Usuario", {
      empresa: (state) => state.empresa,
    }),
  },

  watch: {
    search() {
      if (this.loading) return;
      this.buscaOrcamento();
    },

    options: {
      handler() {
        this.buscaOrcamento();
      },
      deep: true,
    },
  },

  methods: {
    buscaOrcamento() {
      const filtro = `&search=${this.search}`;

      clearTimeout(this.debounce);
      this.debounce = setTimeout(async () => {
        await this.fetchOrcamentos(this.search ? filtro : "");
      }, 500);
    },

    async fetchOrcamentos(filtro) {
      try {
        this.loading = true;
        const { sortBy, sortDesc, page, itemsPerPage } = this.options;

        const response = await getOrcamentos(
          `?page=${page}&sortBy=${sortBy}&sortDesc=${sortDesc}&itemsPerPage=${itemsPerPage}` +
            (filtro ? filtro : "")
        );
        this.orcamentos = response.data;
        this.totalOrcamentos = response.total;

        this.loading = false;
      } catch (error) {
        console.log(error);
        this.loading = false;
      }
    },
    goToOrcamento(item) {
      if (this.POrcamentoVisualizar) {
        this.orcamento = { ...item };
        this.dialogOrcamento = true;
        this.editar = true;
      }
    },

    addOrcamento() {
      this.editar = false;
      this.dialogOrcamento = true;
    },

    async gerarpdf(item) {
      try {
        this.loadingPdfSimples = true;

        this.orcamento = await orcamentoPdfSimples(item.id);

        await pdfGenerator()
          .then((module) => {
            let generate = module.generatePdf;
            generate(this.empresa, this.orcamento).then((response) => {
              this.pdfDataSimples = response;
              this.pdfDialogSimples = true;
            });
          })
          .finally(() => {
            this.loadingPdfSimples = false;
          });
      } catch (error) {
        this.loadingPdfSimples = false;
      }
    },

    async gerarPdfAvancado(item) {
      try {
        this.loadingPdfSimples = true;
        this.orcamento = await orcamentoPdfAvancado(item.id);

        const binaryString = window.atob(this.orcamento);
        const len = binaryString.length;
        const bytes = new Uint8Array(len);
        for (let i = 0; i < len; ++i) {
          bytes[i] = binaryString.charCodeAt(i);
        }
        const blob = new Blob([bytes], { type: "application/pdf" });
        const blobURL = URL.createObjectURL(blob);

        this.pdfDataAvancado = blobURL;
        this.pdfDialogAvancado = true;
      } catch (error) {
        this.loadingPdfSimples = false;
      }
    },
  },

  mounted() {
    this.fetchOrcamentos();
  },
};
</script>

<style></style>
